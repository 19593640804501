import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11')
];

export const server_loads = [];

export const dictionary = {
		"/": [~3],
		"/(main)/about": [~4,[2]],
		"/(main)/contact": [5,[2]],
		"/(main)/events": [~6,[2]],
		"/(main)/events/[slug]": [~7,[2]],
		"/works": [~10],
		"/works/[slug]": [~11],
		"/(main)/writing": [~8,[2]],
		"/(main)/writing/[slug]": [~9,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';